import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`For some reason lately, I've been having a lot of trouble installing apps and running tests in the iOS simulator on Xcode 10 (non-beta). The error message looks like this:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Failed to install the requested application`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`The application's CFBundleVersion is invalid`}</p>
    </blockquote>
    <p>{`To be completely honest, I'm not super sure what causes this error, but it might be related to the fact that I'm using non-semver version numbers (they include a sub-patch number) and `}<a parentName="p" {...{
        "href": "https://forums.developer.apple.com/thread/117322"
      }}>{`the Xcode 11 simulator treats CFBundleVersion numbers differently`}</a>{`. For what it's worth, this started happening when installing the Xcode 11 beta and running it in parallel with Xcode 10.`}</p>
    <p>{`Anyways, the magic fix is to kill CoreSimulatorBridge: in 99% of cases, this has fixed the error for me! 🎉`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`killall -9 CoreSimulatorBridge`}</span></code></pre>
    <blockquote>
      <p parentName="blockquote">{`Note: in a few instances, I've had this not work. Usually, erasing the simulator (Hardware > Erase All Content And Settings) then killing the CoreSimulatorBridge again seems to help.`}</p>
    </blockquote>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      